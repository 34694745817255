import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { useTheme } from "emotion-theming";
import PropTypes from "prop-types";
import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  colors,
  hexRgba,
  mediaquery,
  rem,
  scaleSpacing,
  zIndexes,
  getModalVideoTemplate,
  getArticleIcon,
} from "../../../../styles/theme";
import { UIContext } from "../../../contexts/ui";
import Button from "../../Atoms/Button";
import Image from "../../Atoms/Image";
import RichText from "../../Atoms/RichText";
import IconSvg from "../../Atoms/Svg";
import Link from "../../Atoms/Link";
import {
  NotoBody,
  NotoLarge,
  NotoSmall,
  OswaldH2,
  OswaldH3,
  OswaldH4,
  OswaldH5,
  OswaldH6,
  OswaldOverline4,
} from "../../Atoms/Typography";
import SoundIcon from "../../Atoms/SoundIcon";
import EyeIcon from "../../Atoms/EyeIcon";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ color }) => color};

  ${({ columns }) =>
    columns === 1 &&
    css`
      ${mediaquery.md(css`
        flex-direction: row;
      `)}
    `}

  ${({ split }) =>
    split &&
    css`
      ${mediaquery.md(css`
        &:nth-of-type(-n + 2) {
          grid-row: 1 / 5;
        }
      `)}

      &:nth-of-type(n + 3) {
        border-top: 1px solid ${colors.black};
        flex-direction: row;
        padding-top: ${scaleSpacing(8)};
        ${mediaquery.md(css`
          padding-top: ${scaleSpacing(8)};
        `)};
      }
    `}
`;

const ImageContainer = styled.div`
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const ImageLink = styled(Link)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const ImageWrapper = styled.div`
  width: 100%;

  ${({ columns }) =>
    columns === 1 &&
    css`
      ${mediaquery.md(css`
        width: calc(var(--col) * 13 - var(--gutter-size));
      `)}
    `}

  ${({ small }) =>
    small &&
    css`
      &,
      .gatsby-image-wrapper {
        margin-right: ${scaleSpacing(4)};
        width: 75px;
        ${mediaquery.sm(css`
          width: 150px;
        `)}
      }
    `}

  .gatsby-image-wrapper:after {
    content: "";
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${hexRgba(colors.black, 0.1)};
    z-index: ${zIndexes.middle};
  }
`;

const ContentWrapperLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  text-decoration: none;

  ${({ columns }) =>
    columns === 1 &&
    css`
      ${mediaquery.md(css`
        width: calc(var(--col) * 10 - var(--gutter-size));
        margin: 0 0 0 calc(var(--col) + var(--gutter-size));
      `)}
    `}

  ${({ featured }) =>
    featured &&
    css`
      ${mediaquery.md(css`
        width: calc(var(--col) * 8 - var(--gutter-size));
        padding: ${scaleSpacing(6)} 0 ${scaleSpacing(6)} 0;
      `)}
    `}
`;

const Title = styled.h3`
  ${OswaldH5};
  margin-top: ${scaleSpacing(4)};
  margin-bottom: ${scaleSpacing(4)};

  ${mediaquery.md(css`
    ${OswaldH4};
  `)};

  ${({ columns }) =>
    columns === 1 &&
    css`
      ${OswaldH3};
      margin-top: ${scaleSpacing(4)};
      margin-bottom: 0;

      ${mediaquery.md(css`
        ${OswaldH2};
        margin-top: 0;
      `)};
    `}

  ${({ columns, featured }) =>
    columns === 1 &&
    featured &&
    css`
      ${OswaldH3};
      margin-bottom: ${scaleSpacing(4)};
    `}

  ${({ small }) =>
    small &&
    css`
      margin-bottom: 0;
    `}
`;

const Subtitle = styled.p`
  ${OswaldH6};

  ${mediaquery.md(css`
    ${OswaldH5};
  `)};

  ${({ featured }) =>
    featured &&
    css`
      margin-bottom: ${scaleSpacing(60)};
    `}
`;

const Description = styled.div`
  ${NotoBody};
  line-height: ${rem(32)};

  ${({ featured }) =>
    featured &&
    css`
      ${NotoLarge};
    `}

  p,
  pre {
    line-height: ${rem(32)};
    white-space: normal;

    ${({ featured }) =>
      featured &&
      css`
        ${NotoLarge};
      `}
  }
`;

const TagList = styled.ul`
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  margin: ${scaleSpacing(6)} 0;
  display: flex;

  ${mediaquery.md(css`
    margin: ${scaleSpacing(8)} 0 ${scaleSpacing(6)} 0;
  `)};

  ${({ columns }) =>
    columns == 1 &&
    css`
      margin: ${scaleSpacing(6)} 0 ${scaleSpacing(4)} 0;
    `}
`;

const TagListItem = styled.li`
  display: flex;
  position: relative;
  margin: 0 ${scaleSpacing(4)} ${scaleSpacing(1)} 0;

  &:last-of-type {
    margin-right: 0;
  }

  &:after {
    content: "";
    width: 1px;
    height: 9px;
    transform: translate(-8px, 10px);
    background-color: ${colors.mediumgrey3};
    position: absolute;
    right: -${scaleSpacing(4)};
    top: 0;
  }
  &:last-of-type:after {
    display: none;
  }
`;

const TagCategoryList = styled(TagList)`
  margin: ${scaleSpacing(4)} 0 0;

  ${mediaquery.md(css`
    margin: ${scaleSpacing(6)} 0 0;
  `)}

  ${({ small }) =>
    small &&
    css`
      margin: 0;
      ${mediaquery.md(css`
        margin: 0;
      `)}
    `}
`;

const TagCategoryListItem = styled(TagListItem)`
  &:after {
    transform: translate(-8px, 5px);
    background-color: ${({ color }) => color};
  }
`;

const TagCategory = styled(Button)`
  ${OswaldOverline4};
  background-color: transparent;
  padding: 0;
`;

const StyledTag = styled(Button)`
  ${NotoBody};
  line-height: ${rem(16)};
  background-color: transparent;
  color: ${colors.mediumgrey3};
  padding: ${scaleSpacing(1)} 0;
`;

const CollabWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${scaleSpacing(5.25)};
  margin-bottom: ${scaleSpacing(8)};
  ${NotoBody};
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${scaleSpacing(4)};
  width: ${scaleSpacing(14)};
  height: ${rem(40)};

  .gatsby-image-wrapper {
    display: flex;
    align-items: center;
    height: 50%;
  }

  img {
    display: block;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const CtaLink = styled(Button)`
  margin: ${scaleSpacing(6)} auto 0 0;

  ${mediaquery.md(css`
    margin: auto auto 0 0;
  `)};

  ${({ columns }) =>
    columns > 1 &&
    css`
      margin: ${scaleSpacing(2)} auto 0 0;
      ${mediaquery.md(css`
        margin: ${scaleSpacing(2)} auto 0 0;
      `)};
    `}

  ${({ featured }) =>
    featured &&
    css`
      padding: 0 ${scaleSpacing(6)};
      max-width: calc(100vw - 2 * var(--external-gutter));
      overflow: hidden;
      margin-top: ${scaleSpacing(6)};

      ${mediaquery.md(css`
        overflow: initial;
        margin-top: ${scaleSpacing(6)};
      `)};

      span {
        margin-left: ${scaleSpacing(0.5)};

        span {
          margin-left: ${scaleSpacing(3)};
        }
      }
    `}
`;
const PlayButtonContainer = styled.button`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  background: transparent;
  margin: 0;
  padding: 0;
  cursor: pointer;
  &:before {
    display: none;
  }
`;

const PlayButton = styled(Button)`
  position: absolute;
  right: ${scaleSpacing(6)};
  bottom: ${scaleSpacing(6)};
  margin: auto;
  z-index: ${zIndexes.top};
  justify-content: center;
  span {
    width: ${rem(8)};
  }
  .soundIcon {
    display: flex;
    width: auto;
  }

  ${mediaquery.md(css`
    span {
      width: ${rem(14)};
    }
  `)};
`;

const TypeLabel = styled.div`
  ${NotoSmall}
  color: ${colors.white};
  position: absolute;
  left: ${scaleSpacing(6)};
  top: ${scaleSpacing(6)};
  display: flex;
  flex-direction: row;
  padding: ${scaleSpacing(2)} ${scaleSpacing(4)};

  position: absolute;
  background: ${({ bgColor }) => bgColor};
  border-radius: ${rem(90)};
  z-index: ${zIndexes.middle};
`;

const GetIcon = format => {
  const iconLabel = getArticleIcon(format);

  switch (iconLabel) {
    case "":
      return;
    case "podcast":
      return <SoundIcon active color={colors.white} />;
    case "article":
      return <EyeIcon active color="white" />;
    default:
      return <IconSvg name={iconLabel} fill={colors.white} />;
  }
};

const GetInternalLabel = format => {
  const { t } = useTranslation();
  switch (format?.toLowerCase()) {
    case "podcast":
      return t("tc_articles_sidebar_cta_podcast");
    case "video":
      return t("tc_articles_sidebar_cta_video");
    case "gallery":
      return t("tc_articles_sidebar_cta_gallery");
    case "article":
      return t("tc_articles_sidebar_cta_article");
    default:
      return t("tc_articlepush");
  }
};

const GetExternalLabel = link => {
  const { t } = useTranslation();
  const { hostname } = new URL(link.url);
  const site = hostname.replace("www.", "");
  return `${t("tc_articles_cta_external")} ${site}`;
};

const ConditionalLink = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const Figure = ({
  title,
  date,
  free,
  author,
  website,
  format,
  isExternal,
  isArticle,
  hasArticleIcon,
  hasTagInfos,
  showIcon,
  showDescription,
  showMeta,
  showWebsiteLabel,
  small,
  split,
  subtitle,
  description,
  ctaLabel,
  ctaLink,
  picture,
  video,
  hasSponsor,
  sponsorsDescription,
  sponsors,
  columns,
  linkStyle,
  genre,
  category,
  duration,
  country,
  year,
  tags,
  tagInfos,
  bgColor,
  color,
  featured,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { openModal } = useContext(UIContext);
  const showVideoModal = useCallback(
    e => {
      e.preventDefault();
      openModal({
        modalId: getModalVideoTemplate(video.player),
        modalParams: { video },
      });
    },
    [openModal, video]
  );

  return (
    <Wrapper columns={columns} color={color} split={split}>
      {picture && (
        <ImageWrapper columns={columns} small={small}>
          <ImageContainer>
            {picture?.fluid?.src && (
              <Image source={picture} sizes="60vw" mobileSizes="100vw" />
            )}
            {!!free && (
              <Button
                css={css`
                  position: absolute;
                  bottom: ${scaleSpacing(6)};
                  left: ${scaleSpacing(6)};
                  border-radius: ${rem(15)};
                  padding: ${rem(3)} ${rem(8)} ${rem(5)};
                  line-height: ${rem(12)};
                  text-transform: none;
                `}
                buttonTheme="tagRed"
              >
                {t("curiosity_herowithcarousel_free")}
              </Button>
            )}
            {isArticle && hasArticleIcon && showIcon && (
              <PlayButtonContainer as="div">
                <PlayButton buttonTheme="iconAlpha" as="div" type="">
                  {GetIcon(format)}
                </PlayButton>
              </PlayButtonContainer>
            )}
            {video ? (
              <>
                {website && isExternal && showWebsiteLabel && (
                  <TypeLabel bgColor={colors[website]}>
                    {t(`${website}_website_label`)}
                  </TypeLabel>
                )}
                {showIcon && (
                  <PlayButtonContainer
                    onClick={showVideoModal}
                    ariaLabel="play"
                  >
                    <PlayButton buttonTheme="iconAlpha" as="div" type="">
                      {category === "Podcast" ? (
                        <SoundIcon active color={colors.white} />
                      ) : (
                        <IconSvg
                          name="play"
                          size={14}
                          height={16}
                          fill={colors.white}
                        />
                      )}
                    </PlayButton>
                  </PlayButtonContainer>
                )}
              </>
            ) : (
              <ImageLink link={ctaLink}>
                {website && isExternal && showWebsiteLabel && (
                  <TypeLabel bgColor={colors[website]}>
                    {t(`${website}_website_label`)}
                  </TypeLabel>
                )}
              </ImageLink>
            )}
          </ImageContainer>
        </ImageWrapper>
      )}
      <ConditionalLink
        condition={!ctaLabel && !featured}
        wrapper={children => (
          <ContentWrapperLink columns={columns} link={!ctaLabel && ctaLink}>
            {children}
          </ContentWrapperLink>
        )}
      >
        <ContentWrapper columns={columns} featured={featured}>
          {hasTagInfos && tagInfos?.length > 0 && (
            <TagCategoryList columns={columns} small={small}>
              {tagInfos?.map(label => {
                return (
                  <TagCategoryListItem
                    key={label}
                    color={
                      bgColor === colors.black ? colors.white : theme.primary
                    }
                  >
                    <TagCategory
                      buttonTheme={
                        bgColor === colors.black
                          ? "tagWhite"
                          : `SecondaryTag_${theme.label}`
                      }
                    >
                      {label}
                    </TagCategory>
                  </TagCategoryListItem>
                );
              })}
            </TagCategoryList>
          )}
          {title && (
            <Title columns={columns} small={small} featured={featured}>
              <RichText data={title} clamp={3} />
            </Title>
          )}
          {subtitle && <Subtitle>de {subtitle}</Subtitle>}
          {(tags?.length > 0 ||
            !!duration ||
            !!genre ||
            !!country ||
            !!year) && (
            <TagList columns={columns}>
              {duration && (
                <TagListItem>
                  <StyledTag>{duration}</StyledTag>
                </TagListItem>
              )}

              {genre && (
                <TagListItem>
                  <StyledTag>{genre}</StyledTag>
                </TagListItem>
              )}

              {country && (
                <TagListItem>
                  <StyledTag>{country}</StyledTag>
                </TagListItem>
              )}

              {year && (
                <TagListItem>
                  <StyledTag>{year}</StyledTag>
                </TagListItem>
              )}

              {tags?.map(({ label }) => {
                if (!label) return null;
                return (
                  <TagListItem key={label}>
                    <StyledTag>{label}</StyledTag>
                  </TagListItem>
                );
              })}
            </TagList>
          )}
          {description && showDescription && (
            <Description featured={featured}>
              <RichText
                data={description}
                clamp={6}
                clampMd={featured ? 6 : 3}
              />
            </Description>
          )}
          {(date || author) && showMeta && (
            <TagList columns={columns}>
              {author && (
                <TagListItem>
                  <StyledTag>{author}</StyledTag>
                </TagListItem>
              )}

              {date && (
                <TagListItem>
                  <StyledTag>{date}</StyledTag>
                </TagListItem>
              )}
            </TagList>
          )}
          {hasSponsor && (
            <CollabWrapper>
              {sponsorsDescription && (
                <span>
                  <RichText data={sponsorsDescription} />
                </span>
              )}

              {sponsors?.map(({ logo, uid }) => {
                if (!uid) return null;
                return (
                  <LogoContainer key={uid}>
                    <Image
                      source={logo}
                      sizes="40"
                      style={{ width: "100%", height: "100%", display: "flex" }}
                      imgStyle={{
                        height: "auto",
                        width: "100%",
                        margin: "auto",
                        bottom: 0,
                        right: 0,
                      }}
                      isFixed
                    />
                  </LogoContainer>
                );
              })}
            </CollabWrapper>
          )}
          {featured && ctaLink && (
            <CtaLink
              buttonTheme="primaryDark"
              ariaLabel={ctaLabel}
              link={ctaLink}
              featured={featured}
            >
              <EyeIcon active color="white" />
              <span>
                {ctaLabel || isExternal
                  ? GetExternalLabel(ctaLink)
                  : GetInternalLabel(format)}
              </span>
            </CtaLink>
          )}
          {ctaLabel && ctaLink && showMeta && (
            <CtaLink
              buttonTheme={linkStyle ? linkStyle : "primary"}
              link={ctaLink}
              columns={columns}
            >
              {ctaLabel}
            </CtaLink>
          )}
        </ContentWrapper>
      </ConditionalLink>
    </Wrapper>
  );
};

Figure.propTypes = {
  website: PropTypes.string,
  format: PropTypes.string,

  picture: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
  author: PropTypes.string,
  subtitle: PropTypes.string,

  description: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  label: PropTypes.string,
  free: PropTypes.bool,
  duration: PropTypes.string,
  genre: PropTypes.string,
  category: PropTypes.string,
  country: PropTypes.string,
  year: PropTypes.number,
  video: PropTypes.object,

  logo: PropTypes.object,
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.object,
  hasSponsor: PropTypes.bool,
  sponsorsDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sponsors: PropTypes.array,

  tags: PropTypes.array,
  tagInfos: PropTypes.array,

  isExternal: PropTypes.bool,
  isArticle: PropTypes.bool,
  hasArticleIcon: PropTypes.bool,
  hasTagInfos: PropTypes.bool,
  showIcon: PropTypes.bool,
  showDescription: PropTypes.bool,
  showMeta: PropTypes.bool,
  showWebsiteLabel: PropTypes.bool,
  small: PropTypes.bool,
  split: PropTypes.bool,
  featured: PropTypes.bool,

  columns: PropTypes.number,
  linkStyle: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
};

Figure.defaultProps = {
  bgColor: colors.white,
  hasTagInfos: false,
  showIcon: true,
  showDescription: true,
  showMeta: true,
  showWebsiteLabel: true,
  split: false,
};

export default React.memo(Figure);
